import React, { useEffect, useState } from 'react';
import { LinkPaymentMethod } from '@bit9s/pnp-packages/';
import ReactDOM from 'react-dom/client';


function App() {

const initializeComponent = ({ elementId, component }: {elementId: string, component: 'linkPaymentMethod'}) => {

  const element = document.getElementById(elementId) ?? ''
  const usersHtml = <LinkPaymentMethod />

  const el = ReactDOM.createRoot(
    document.getElementById(elementId) as HTMLElement
  );


  el.render(<LinkPaymentMethod/>)
}



useEffect(()=>{

   // @ts-ignore comment
   window.loadPnpWidget = (function({elementId}) {
    initializeComponent({ 
      elementId: elementId, 
      component:  'linkPaymentMethod'
    })

  })

}, [])

  return (
    <div style={{textAlign: 'center'}}>
      <div className="App" id="app-root">
      </div>

      <div className="App" id="app-root2">
      </div>
    </div>

  );
}

export default App;
